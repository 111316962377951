import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { initializeApp } from "./v2/utils/loaderUtils";
import { UiVersion } from "./helpers/Constants";
import NotFound from "./NotFound";
import LoadingScreen from "./landing/LoadingScreen";

const AppV2Router = lazy(() =>
  import("./v2/router").then((module) => ({ default: module.AppV2Router }))
);
const AppV3Router = lazy(() =>
  import("./v3/router").then((module) => ({ default: module.AppV3Router }))
);
const AppAuthenticationRouter = lazy(() =>
  import("./authentication/router").then((module) => ({
    default: module.AppAuthenticationRouter,
  }))
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const renderApplication = (version: UiVersion) => {
  let rootApp: JSX.Element;
  switch (version) {
    case UiVersion.STANDARD:
      rootApp = <AppV3Router />;
      break;
    case UiVersion.DEFAULT:
      rootApp = <AppV2Router />;
      break;
    case UiVersion.DEMO: //todo
    case UiVersion.AUTHENTICATION:
      rootApp = <AppAuthenticationRouter />;
      break;
    default:
      rootApp = <NotFound />;
  }

  return root.render(
    <Suspense fallback={<LoadingScreen />}>
      <React.StrictMode>
        <Provider store={store}>{rootApp}</Provider>
      </React.StrictMode>
    </Suspense>
  );
};

initializeApp().then((theme) => {
  return renderApplication(theme);
});

import { EndPoints, ItemTypes, UiVersion } from "./Constants";
import { Dispatch } from "@reduxjs/toolkit";
import { api } from "./AxiosConfig";
import {
  CartItem,
  CategorizedProducts,
  Category,
  ProductInterface,
  RegistrationRequest,
  ServerOrder,
  WaitlistRequest,
} from "../types/Generic";
import {
  addCart,
  manageModal,
  MODAL_TRIGGER,
  OrderSubmitState,
  setCategories,
  setCategorizedProducts,
  setCustomerOrders,
  setOrderSubmitState,
} from "../redux/slices/Items";
import { SetCategorizedProducts } from "../types/PayloadActionTypes";
import {
  RegistrationSubmitState,
  setLoadingState,
  setRegistrationSubmitState,
  setUser,
  setWaitlistSubmitState,
  User,
  WaitListSubmitState,
} from "../redux/slices/Client";
import { get, set } from "lockr";

export const getClient = async (
  username: string,
  segment: string
): Promise<User> => {
  const userSettings = await api.get(`/user/${username}/${segment}/settings`);
  return userSettings.data;
};

// todo: This function should be deprecated after completing switch to
//  url segmenting.
export const fetchUserSettings = (
  tableNum: number,
  identificationNumber: number,
  id: string,
  dispatch: Dispatch<any>
): Promise<boolean> => {
  return api
    .get(`/user/${identificationNumber}/settings`)
    .then((response) => {
      if (response.data) {
        const user: User = {
          id: response.data.id,
          theme: UiVersion.DEMO,
          segment: "",
          username: "",
          currency: response.data.currency,
          languages: response.data.languages,
          hasSegments: response.data.hasSegments,
          defaultLang: response.data.defaultLang,
          name: response.data.name,
          address: response.data.address,
          brief: response.data.brief,
          wifi: response.data.wifi,
          contacts: response.data.contacts,
          activities: response.data.activities,
          canPlaceOrder: response.data.canPlaceOrder,
          clientVersion: response.data.clientVersion,
        };
        const slug = response.data.name.toLowerCase().replaceAll(" ", "-");
        dispatch(setUser({ user, tableNum }));
        set("user-slug", slug);
        return true;
      }
      return false;
    })
    .catch((error) => {
      console.log(error);
      dispatch(setCategories({ isSuccess: false }));
      return false;
    });
};

/* The fetchItems function requests all products (drinks and food) from the server, all at once
 * */
export const fetchItems = (
  clientId: string,
  dispatch: Dispatch<any>,
  activeProductType: number,
  segment: string
): Promise<boolean> => {
  const base = EndPoints.ALL.ITEMS;
  const path = base + `/${clientId}/items`;

  return api
    .get(path)
    .then((response) => {
      const { drink_categories, food_categories } = response.data;
      const foodCategory: Category[] = food_categories.map((category: any) => {
        return {
          id: category.id,
          name: category.name,
          product: category.food,
        };
      });
      const drinkCategory: Category[] = drink_categories.map(
        (category: any) => {
          return {
            id: category.id,
            name: category.name,
            product: category.drinks,
          };
        }
      );
      const categorizedProducts: CategorizedProducts = {
        food: foodCategory,
        drink: drinkCategory,
      };
      const initialActiveProductCategory: Category[] =
        activeProductType === ItemTypes.Drink ? drinkCategory : foodCategory;
      const payload: SetCategorizedProducts = {
        categorizedProducts,
        initialActiveProductCategory,
      };

      dispatch(setCategorizedProducts(payload));

      // set initial cart
      const storedCart: Array<{
        [key: string]: number;
      }> = get("cart");
      if (storedCart.length) {
        const mergedCategories = [
          ...categorizedProducts.food,
          ...categorizedProducts.drink,
        ];
        const products: Array<ProductInterface> = [];
        mergedCategories.forEach((category) => {
          category.product.forEach((product) => products.push(product));
        });
        const cart: CartItem[] = [];

        products.forEach((product) => {
          if (storedCart.some((item) => item[product.id])) {
            const storageCartItem = storedCart.find((item) => item[product.id]);

            cart.push({
              id: product.id,
              count: storageCartItem ? storageCartItem[product.id] : 1,
              productPhoto: product.productPhoto,
              descriptions: product.descriptions,
              price: segment === "0" ? product.price : product.prices[segment],
              prices: product.prices,
              names: product.names,
              categoryId: product.categoryId,
            });
          }
        });
        dispatch(addCart({ cart: cart }));
      }

      return true;
    })
    .catch(() => {
      dispatch(setCategories({ isSuccess: false }));
      return false;
    });
};

export const fetchActiveItemAndProducts = (
  clientId: string,
  dispatch: Dispatch<any>,
  activeProductType: number
) => {
  api
    .get(`/client/${clientId}/items/${activeProductType}`)
    .then((response) => {});
};

export const fetchOrders = (
  clientId: string,
  mtId: string,
  dispatch: Dispatch<any>
) => {
  if (!clientId || !mtId) return false;
  api.get(EndPoints.ORDER.GET_ALL(clientId, mtId)).then((response) => {
    if (response.data.success) {
      dispatch(setCustomerOrders(response.data.orders));
    }
  });
};

export const submitOrder = (order: ServerOrder, dispatch: Dispatch<any>) => {
  if (!order) return;
  dispatch(setOrderSubmitState(OrderSubmitState.Requested));
  api
    .post(EndPoints.ORDER.SUBMIT, order)
    .then((response) => {
      if (response.status === 200) {
        dispatch(setOrderSubmitState(OrderSubmitState.Successful));
        setTimeout(() => {
          dispatch(addCart({ cart: [] }));
          dispatch(
            manageModal({ isOpen: false, triggeredBy: MODAL_TRIGGER.CART })
          );
          dispatch(setOrderSubmitState(OrderSubmitState.Stale));
          fetchOrders(order.clientId, order.mtId, dispatch);
        }, 3000);
      } else {
        dispatch(setOrderSubmitState(OrderSubmitState.Failed));
        setTimeout(() => {
          dispatch(
            manageModal({ isOpen: false, triggeredBy: MODAL_TRIGGER.CART })
          );
          dispatch(setOrderSubmitState(OrderSubmitState.Stale));
        }, 3000);
      }
    })
    .catch(() => {
      dispatch(setOrderSubmitState(OrderSubmitState.Failed));
    });
};

export const logClientActivity = (username: string, ip: string) => {
  const logData = {
    username,
    ip,
    requestTime: new Date().toISOString(),
  };
  api.post(EndPoints.ANALYTICS.CLIENT_ACTIVITY, logData).catch((error) => {
    console.error(error);
  });
};

export const submitWaitlistRequest = (
  waitlistData: WaitlistRequest,
  dispatch: Dispatch<any>
) => {
  dispatch(setWaitlistSubmitState(WaitListSubmitState.Requested));
  api
    .post(EndPoints.WAITLIST.SUBMIT, waitlistData)
    .then((response) => {
      if (response.status === 200) {
        dispatch(setWaitlistSubmitState(WaitListSubmitState.Successful));
      } else {
        dispatch(setWaitlistSubmitState(WaitListSubmitState.Failed));
      }
      setTimeout(() => {
        dispatch(
          manageModal({ isOpen: false, triggeredBy: MODAL_TRIGGER.CART })
        );
        dispatch(setWaitlistSubmitState(WaitListSubmitState.Stale));
      }, 3000);
    })
    .catch(() => {});
};


export const submitRegistrationRequest = async (
  registrationData: RegistrationRequest,
  dispatch: Dispatch<any>
) => {
  try {
    dispatch(setLoadingState(true));
    dispatch(setRegistrationSubmitState(RegistrationSubmitState.Requested));

    const response = await api.post(EndPoints.REGISTER.SUBMIT, registrationData);

    if (response.status === 200) {
      dispatch(setRegistrationSubmitState(RegistrationSubmitState.Successful));
    } else {
      dispatch(setRegistrationSubmitState(RegistrationSubmitState.Failed));
    }

    return response;
  } catch (e) {
    dispatch(setRegistrationSubmitState(RegistrationSubmitState.Failed));
    return e;
  } finally {
    dispatch(setLoadingState(false));
  }
}
import { store } from "../../redux/store";
import {
  fetchItems,
  fetchOrders,
  getClient,
  logClientActivity,
} from "../../helpers/APIHelpers";
import { setUser } from "../../redux/slices/Client";
import { ItemTypes, UiVersion } from "../../helpers/Constants";
import { getIpAddress } from "../../helpers/fetchIpAddress";
import { v4 as mtIdGenerate } from "uuid";
import { get, set } from "lockr";
import { getClientDetails } from "./pathUtils";

export const loadData = (): null | Promise<boolean> => {
  const { Items, Client } = store.getState();
  if (Items.activeProductCategories.length) return null;
  return fetchItems(Client.user.id, store.dispatch, Items.itemType, "");
};

export const initializeApp = async () => {
  const client = getClientDetails();
  if (client.username) {
    if(client.username === 'register'){
      return UiVersion.AUTHENTICATION;
    }
    return getClient(client.username, client.segment)
      .then((user) => {
        user.username = client.username;
        user.segment = client.segment;
        //todo if (user.hasSegments) verify segment exists.
        store.dispatch(setUser({ user, tableNum: Number(client.tableNumber) }));
        return user;
      })
      .then(async (user) => {
        const ip = await getIpAddress();
        logClientActivity(user.name, ip);
        if (!get("mtId")) {
          set("mtId", mtIdGenerate());
        }
        return user;
      })
      .then(async (user) => {
        await fetchItems(
          user.id,
          store.dispatch,
          ItemTypes.Drink,
          client.segment
        );
        return user;
      })
      .then((user) => {
        if (user.canPlaceOrder) {
          fetchOrders(user.id, get("mtId"), store.dispatch);
        }
        return user.theme;
      })
      .catch((error) => {
        console.log(error);
        return UiVersion.DEMO;
      });
  }

  return UiVersion.DEFAULT;
};

import axios from "axios";

export const nodeEnv = process.env.NODE_ENV;
let uri = process.env.REACT_APP_API_URL_DEV;
if (nodeEnv !== "development") {
  const baseSite = window.location.origin;
  if (baseSite === process.env.REACT_APP_CLIENT_ORIGIN_TESTING) {
    uri = process.env.REACT_APP_API_URL_PROD_TESTING;
  }
  if (baseSite === process.env.REACT_APP_CLIENT_ORIGIN_PROD) {
    uri = process.env.REACT_APP_API_URL_PROD;
  }
}

const username = process.env.REACT_APP_BASIC_AUTH_USERNAME;
const password = process.env.REACT_APP_BASIC_AUTH_PASSWORD;
const salt = process.env.REACT_APP_BASIC_AUTH_SALT;

const credentials = window.btoa(`${username}:${password}`);

export const api = axios.create({
  baseURL: uri,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Basic ${credentials}${salt}`,
  },
});
